<template>
    <div>
        <div class="title">
            <img src="@/assets/common/tsjy.png" alt="">
            <p>{{title}}</p>
        </div>
        <div class="content">
            <div class="contentitle">
                <span class="verticaline"></span>
                <span class="ctitle">回答</span>
            </div>
            <span class="divceline"></span>
            <div class="contentbody">
                <p>{{body}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import {getcomplainlist} from '@/api/common.js'
export default {
    data() {
        return {
            title: '欠款15万，有什么办法可以加速赚钱还清？',
            body: '三年前做生意失败加上自己大手大脚花钱，欠下来60多万!后来逼自己一下摆了个地摊做吃的!慢慢坚持下来，我现在债务基本还清!每个月还有闲钱给爸妈，有前做生意失败加上自己大手大脚花钱。'
        }
    },
    created() {
        getcomplainlist({comid: this.$route.query.comid || '',sid: this.$route.query.sid || ''}).then(res => {
            console.log(res)
            this.title = res.data.data.tsreason
            this.body = res.data.data.opresult
        })
    },
    methods: {
        
    }
}
</script>
<style lang="stylus" scoped>
.title
  height 1.92rem
  background white
  display flex
  align-items center
  margin-bottom 0.4rem
  img 
    width 0.53rem
    height 0.54rem
    margin 0 0.15rem 0 0.49rem
  p
   font-size:0.43rem;
   font-family:PingFang SC;
   font-weight:bold;
   color:rgba(56,56,56,1);
.content
  background white
  .divceline
    display block
    height 1px
    width calc(100vw - 1rem)
    margin 0 auto
    background  #EFEFEF
  .contentitle
    height 1.29rem
    display flex
    align-items center
    .verticaline
      display block
      background #FB6821
      height 0.35rem
      width 0.05rem
      margin 0 0.2rem 0 0.61rem
    .ctitle
      font-size:0.43rem;
      font-family:PingFang SC;
      font-weight:bold;
      color:rgba(56,56,56,1);
  .contentbody
    font-size:0.39rem;
    font-family:PingFang SC;
    font-weight:500;
    color:rgba(56,56,56,1);
    line-height:0.66rem;
    padding 0.43rem 0.5rem
</style>